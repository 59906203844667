import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
  
<svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 198 198" width="198" height="198" {...props}>  <image id="image0" width="198" height="198" x="0" y="0" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAVQAAAE2CAMAAAAJcJrWAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAUVBMVEUAAAB30/t21Px21Pt2 0/xmzP930/t30/x30/x/1P920/x40v911/921Px/3/921Pt40/p41fx11ft5zv940v9Vqv941v93 zP931Px31Pz////GUUAfAAAAGXRSTlMAgMyhYwWgyXwGzhENygiFNVVDFSIDEw9rfbEYzgAAAAFi S0dEGnVn5DIAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfoAgkODyyO1XKDAAAC5klEQVR4 2u3a2W0cQRQEwSEl6r5v+e+oABogiMAktbWMNKAfOr7rOI7j5vYqe3b8e89PvHt3/+KL31fZyweg vjrx7muoUB8U1CCoQVCDoAZBDYIaBDUIahDUIKhBUIOgBkENghoENQhqENQgqEFQg6AGQQ2CGgQ1 CGoQ1CCoQVCDoAZBDYIaBDUIahDUIKhBUIOgBkENghoENQhqENQgqEFQg6AGQQ2CGgQ1CGoQ1CCo QVCDoAZBDYIaBDUIahDUIKhBUIOgBkENghoENQhqENQgqEFQg64B9c3bC+vdFaC+f8AfLi6oUDeC CnUjqFA3ggp1I6hQN4IKdSOoUDeCCnUjqFA3ggp1I6hQN4IKdSOoUDeCCnWjDycO4w6oVVCDoAZB DYIaBDUIahDUIKhBUIOgBkENghoENQhqENQgqEFQg6AGQQ2CGgQ1CGoQ1CCoQVCDoAZBDYIaBDUI ahDUIKhBUIOgHsfHUwzus/mHCnUrqFA3ggp1I6hQN4IKdSOoUDeCCnUjqFA3ggp1I6hQN4IKdSOo UDeCCnUjqFA3ggp1I6hQN4IKdSOoUDeCCnUjqFA3ggp1I6hQN4IKdSOoUDeCCnUjqFA3ggp1I6hQ N4IKdSOoUDeCCnUjqFA3grqA+unzGX353y6XhSqoSVCDoAZBDYIaBDUIahDUIKhBUIOgBkENghoE NQhqENQgqEFQg6AGQQ2CGgQ1CGoQ1CCoQVCDoAZBDYIaBDXodNSv355E3x8V9Zwl9cX3A+r5QQ2C GgQ1CGoQ1CCoQVCDoAZBDYIaBDUIahDUIKhBUIOgBkENghoENQhqENQgqEFQg6AGQQ2CGgQ1CGoQ 1CCoQVCDoAZBDYIaBDUIahDUIKhBUIOgBkENghoENQhqENQgqEFQg6AGQQ2CGgQ1CGoQ1CCoQVCD oAZBDYIaBDUIahDUIKhBUIOgBkENghoENQhqENQgqEFQg6AGPS7qze2T6OffUH+dd+fu+AMzerkT A/kw6wAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyNC0wMi0wOVQxMzoxNTo0NCswMTowMDYpKW0AAAAl dEVYdGRhdGU6bW9kaWZ5ADIwMjQtMDItMDlUMTM6MTU6NDQrMDE6MDBHdJHRAAAAAElFTkSuQmCC"/>
</svg>
  );
};

export default Icon;
